<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formEmpresa" lazy-validation>
      <v-container fluid>
        <v-widget title="Empresa">
          <template v-slot:widget-content>
            <v-tabs v-model="abaAtiva" @change="tabChange">
              <v-tab :key="1">Dados</v-tab>
              <v-tab :key="2">Módulos</v-tab>
              <v-tab :key="3">Configurações Tributárias</v-tab>
              <v-tab :key="4">Configurações DF-e</v-tab>
              <v-tab :key="5">Configurações Sistema</v-tab>
              <v-tab :key="6">Responsável</v-tab>

              <v-tabs-items v-model="abaAtiva">
                <v-divider />

                <v-tab-item :key="1" eager>
                  <v-container fluid class="pa-0 pt-4">
                    <v-row dense>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          outlined
                          autocomplete="off"
                          role="presentation"
                          label="CNPJ"
                          v-model="empresa.cnpj"
                          :loading="carregando"
                          v-mask="'##.###.###/####-##'"
                          :rules="validarCnpj"
                          required
                          :readonly="!!empresa._id || carregando"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field outlined autocomplete="off" role="presentation" label="Inscrição Estadual" v-model="empresa.ie" :rules="[...validarInscricao]" required></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field outlined autocomplete="off" role="presentation" label="Inscrição Municipal" v-model="empresa.im"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-select outlined autocomplete="off" role="presentation" label="Regime Tributário" v-model="empresa.configuracaoTributaria.regime_tributario" :items="constantes.REGIMES_TRIBUTARIOS" required :rules="validarRegime"></v-select>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-text-field outlined autocomplete="off" role="presentation" label="Razão Social" v-model="empresa.razao" required :rules="validadarrazaoSocialNome"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-text-field outlined autocomplete="off" role="presentation" label="Nome Fantasia" v-model="empresa.fantasia"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <campo-cep outlined autocomplete="off" role="presentation" v-mask="'#####-###'" label="CEP" buscarcep @resultado="setEndereco" v-model="empresa.cep" append-icon="map" data-cy="cep"></campo-cep>
                      </v-col>
                      <v-col cols="12" sm="7">
                        <v-text-field outlined autocomplete="off" role="presentation" label="Logradouro" v-model="empresa.logradouro" :rules="validarLogradouro"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field outlined autocomplete="off" role="presentation" label="Número" v-model="empresa.numero" :rules="validarNumero"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field outlined autocomplete="off" role="presentation" label="Complemento" v-model="empresa.complemento"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field outlined autocomplete="off" role="presentation" label="Bairro" v-model="empresa.bairro" :rules="validarBairro"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="5">
                        <v-autocomplete outlined autocomplete="off" role="presentation" label="Estado" v-model="empresa.estado" :items="constantes.ESTADOS_AUTOCOMPLETE" @change="filtrarCidadesEstado(empresa.estado)"></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="7">
                        <v-autocomplete
                          outlined
                          autocomplete="off"
                          role="presentation"
                          label="Cidade"
                          v-model="empresa.codigoCidade"
                          @change="setCidade(empresa.codigoCidade)"
                          :items="cidades"
                          :rules="validarCidade"
                          :disabled="cidades.length == 0 || $store.state.cidades.cidadesCarregando"
                          :loading="$store.state.cidades.cidadesCarregando"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field outlined autocomplete="off" role="presentation" label="Celular" v-model="empresa.celular" :rules="validarTelefone" v-mask="'(##) #####-####'"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field outlined autocomplete="off" role="presentation" label="Telefone" v-model="empresa.telefone" :rules="validarTelefone" v-mask="'(##) ####-####'"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field outlined autocomplete="off" role="presentation" label="E-mail" v-model="empresa.email" :rules="validarEmail"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>

                <v-tab-item :key="2" eager>
                  <v-container fluid class="pa-0 pt-4">
                    <v-row dense>
                      <v-col cols="12" sm="4">
                        <v-list dense>
                          <v-list-item>
                            <v-list-item-action>
                              <v-checkbox v-model="empresa.modulos.cupomFiscal"></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title>Cupom Fiscal</v-list-item-title>
                              <v-list-item-subtitle>Módulo responsável por...</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-action>
                              <v-checkbox v-model="empresa.modulos.notaFiscal"></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title>Nota Fiscal</v-list-item-title>
                              <v-list-item-subtitle>Módulo responsável por...</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-action>
                              <v-checkbox v-model="empresa.modulos.notaFiscalDeposito"></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title>Nota Fiscal Depósito</v-list-item-title>
                              <v-list-item-subtitle>Módulo responsável por...</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-action>
                              <v-checkbox v-model="empresa.modulos.manifesto"></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title>Manifesto</v-list-item-title>
                              <v-list-item-subtitle>Módulo responsável por...</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-action>
                              <v-checkbox v-model="empresa.modulos.retaguarda"></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title>Retaguarda</v-list-item-title>
                              <v-list-item-subtitle>Módulo responsável por...</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item disabled>
                            <v-list-item-action>
                              <v-checkbox v-model="empresa.modulos.conhecimento" disabled></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title>Conhecimento de Transporte</v-list-item-title>
                              <v-list-item-subtitle>Módulo responsável por...</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>

                <v-tab-item :key="3" eager>
                  <v-container fluid class="pa-0 pt-4">
                    <v-row dense>
                      <v-col cols="12" sm="2">
                        <v-text-field outlined autocomplete="off" role="presentation" label="CNAE" v-model="empresa.cnae" v-mask="'####-#/##'" @input="consultarCnae(empresa.cnae)" required></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="10">
                        <v-text-field outlined autocomplete="off" role="presentation" label="Descrição da Atividade Econômica" v-model="empresa.descricao_cnae" readonly required :rules="validarDescCnae"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-select
                          outlined
                          autocomplete="off"
                          role="presentation"
                          label="Ramo de Atividade"
                          v-model="empresa.configuracaoTributaria.ramoAtividade"
                          :items="constantes.RAMO_ATIVIDADES"
                          @change="definirCfopRamoAtividade"
                          required
                          :rules="validarRamoAtiv"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>

                <v-tab-item :key="4" eager>
                  <v-container class="pa-0 pt-4">
                    <v-row dense>
                      <v-col cols="12">
                        <v-radio-group v-model="dfe" row class="mt-0">
                          <v-radio label="NF-e" :value="dfeNfe" hideDetails></v-radio>
                          <v-radio label="NFC-e" :value="dfeNfce" hideDetails></v-radio>
                          <v-radio label="MDF-e" :value="dfeMdfe" hideDetails></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="2">
                        <v-select outlined autocomplete="off" role="presentation" label="Versão NF-e" v-model="empresa.versao" :items="constantes.VERSAO_NFE"></v-select>
                      </v-col>
                      <v-col cols="12" sm="5">
                        <v-select outlined autocomplete="off" role="presentation" label="Formato de Impressão" v-model="empresa.formato_impressao" :items="constantes.FORMATO_IMPRESSAO"></v-select>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field outlined autocomplete="off" role="presentation" label="Série Padrão" v-model="empresa.configuracaoTributaria.seriePadrao" v-mask="'###'" :rules="validarSerie" required></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-select outlined autocomplete="off" role="presentation" label="Frete" v-model="empresa.frete_padrao" :items="constantes.MODELO_FRETE" :rules="validarFretePadrao"></v-select>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-select outlined autocomplete="off" role="presentation" label="Forma de Pagamento Padrão" v-model="empresa.forma_pagamento_padrao" :items="constantes.FORMA_PAGAMENTO" :rules="validarFormaPgtoPadrao"></v-select>
                      </v-col>
                      <v-col cols="12" sm="5">
                        <v-select outlined autocomplete="off" role="presentation" label="Calcular Preço de Custo" v-model="empresa.calculo_custo" :items="constantes.CALCULOS_PRECO_CUSTO"></v-select>
                      </v-col>

                      <v-col cols="12" sm="4">
                        <v-select outlined autocomplete="off" role="presentation" label="Configuração CFOP" @change="atualizarConfigCfop()" v-model="empresa.configuracaoTributaria.config_cfop_padrao" :items="constantes.CONFIG_CFOP_PADRAO"></v-select>
                      </v-col>
                      <v-col cols="12" sm="4" v-if="empresa.configuracaoTributaria.config_cfop_padrao !== '1'">
                        <v-text-field outlined autocomplete="off" role="presentation" label="CFOP Padrão" v-mask="'####'" v-model="empresa.configuracaoTributaria.cfop_padrao" :rules="validarCfopPadrao"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" v-if="ehSimplesNacional">
                        <v-select outlined autocomplete="off" role="presentation" label="CSOSN" :items="constantes.CSOSN" v-model="empresa.configuracaoTributaria.csosn" @change="definirInfoComplementar()" required :rules="validarCsosn"></v-select>
                      </v-col>
                      <v-col cols="12" sm="4" v-if="ehSimplesNacionalComPermissaoCredito">
                        <v-text-field outlined autocomplete="off" role="presentation" label="Alíquota Crédito SN" v-model="empresa.configuracaoTributaria.aliq_cred_sn" required></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-textarea outlined autocomplete="off" role="presentation" label="Informações Complementares" v-model="empresa.configuracaoTributaria.info_complementar" required></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>

                <v-tab-item :key="5" eager>
                  <v-container fluid class="pa-0 pt-4">
                    <v-row dense>
                      <v-col cols="12" sm="5">Configurações Sistema</v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>

                <v-tab-item :key="6" eager>
                  <v-container fluid class="pa-0 pt-4">
                    <v-row dense>
                      <v-col cols="12" sm="5">
                        <v-text-field outlined autocomplete="off" role="presentation" label="Nome" v-model="empresa.nomeResponsavel"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field persistentHint outlined autocomplete="off" role="presentation" v-model="empresa.cpfResponsavel" name="cpf" label="CPF" id="cpf" v-mask="'###.###.###-##'"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field outlined autocomplete="off" role="presentation" label="E-mail" v-model="empresa.usuario.email" :rules="validarEmail" :readonly="!!empresa._id"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete outlined autocomplete="off" role="presentation" label="Contador" v-model="empresa.contador" :items="contadores"> </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </template>

          <template v-slot:widget-footer-action>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed class="mr-2" @click="cancelar">Cancelar</v-btn>
              <v-btn depressed color="primary" :loading="carregando" @click="salvar()" :disabled="!valid">Salvar</v-btn>
            </v-card-actions>
          </template>
        </v-widget>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import constantes from '@/util/constantes';
import validador from '@/util/validador';
import { mask } from 'vue-the-mask';

export default {
  name: 'FormularioEmpresa',
  directives: { mask },

  data: () => ({
    valid: false,
    validarSerie: [(v) => !!v || 'Série obrigatória', (v) => (v && v.length === 3) || 'Formato Inválido, ex: 001'],
    validarCnpj: [(v) => (v && v.length === 18 ? validador.cnpj(v) : false) || 'CNPJ Inválido'],
    validadarrazaoSocialNome: [(v) => !!v || 'Razão/Nome Obrigatório'],
    validarCep: [(v) => !!v || 'CEP Obrigatório'],
    validarLogradouro: [(v) => !!v || 'Logradouro Obrigatório'],
    validarNumero: [(v) => !!v || 'Número Obrigatório'],
    validarBairro: [(v) => !!v || 'Bairro Obrigatório'],
    validarCidade: [(v) => !!v || 'Cidade Obrigatória'],
    validarPais: [(v) => !!v || 'País Obrigatório'],
    validarTelefone: [(v) => !!v || 'Telefone Obrigatório'],
    validarAmbiente: [(v) => !!v || 'Ambiente Obrigatório'],
    validarFretePadrao: [(v) => !!v || 'Frete Padrão Obrigatório'],
    validarFormaPgtoPadrao: [(v) => !!v || 'Frete Padrão Obrigatório'],
    validarTipoCertificado: [(v) => !!v || 'Tipo de Cert. Obrigatório'],
    validarDescCnae: [(v) => !!v || 'Informe um CNAE válido'],
    validarRamoAtiv: [(v) => !!v || 'Ramo Obrigatório'],
    validarCfopPadrao: [(v) => !!v || 'CFOP Padrão Obrigatório'],
    validarRegime: [(v) => !!v || 'Regime Trib. Obrigatório'],
    validarCsosn: [(v) => !!v || 'CSOSN Obrigatório'],
    validarEmail: [(v) => (v && validador.email(v)) || 'E-mail Inválido'],
    validarNomeUsuario: [(v) => !!v || 'Nome do usuário obrigatório'],
    constantes: constantes,
    cidadeSelecionada: {},
    abaAtiva: 0,
    abaDFeAtiva: 0,
    loading: false,
  }),

  computed: {
    ...mapState('empresa', {
      empresa: 'empresa',
      carregando: 'carregando',
      certificadoCarregado: 'certificadoCarregado',
    }),

    ...mapState('cidades', {
      cidades: 'cidades',
      cidadesCarregando: 'cidadesCarregando',
    }),

    ...mapGetters('contador', {
      contadores: 'contadores',
    }),

    ehSimplesNacional() {
      const configTributaria = this.$store.state.empresa.empresa.configuracaoTributaria;
      if (configTributaria) {
        if (configTributaria.regime_tributario === 1 || configTributaria.regime_tributario === 2) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    ehSimplesNacionalComPermissaoCredito() {
      const configTributaria = this.$store.state.empresa.empresa.configuracaoTributaria;
      if (configTributaria) {
        if ((configTributaria.regime_tributario === 1 || configTributaria.regime_tributario === 2) && configTributaria.csosn == 101) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions('cidades', {
      filtrarCidadesEstado: 'filtrarCidadesEstado',
    }),

    ...mapMutations('empresa', {
      setCidade: 'setCidade',
      atualizarConfigCfop: 'atualizarConfigCfop',
    }),

    tabChange(index) {
      if (!this.empresa._id && index === 3 && validador.email(this.empresa.email)) {
        this.$store.commit('empresa/preencherEmailPadrao');
      }
    },

    validarInscricao() {
      if (this.empresa.estado) {
        if (!validador.inscricaoEstadual(`${this.empresa.ie}-${this.empresa.estado}`)) {
          return `Inscrição Estadual inválida`;
        } else {
          return true;
        }
      }
      return true;
    },

    definirCfopRamoAtividade() {
      const configTrib = this.empresa.configuracaoTributaria;
      if (configTrib.ramo_atividade == '1') {
        // Comércio
        configTrib.cfop_padrao = '5102';
      } else if (configTrib.ramo_atividade == '3') {
        // Indústria
        configTrib.cfop_padrao = '5101';
      }
    },

    async salvar() {
      if (this.$refs.formEmpresa.validate()) {
        try {
          await this.$store.dispatch('empresa/salvar');
          this.$router.push('/admin/empresas');
        } catch (error) {
          this.$store.commit('setMensagemErro', error);
        }
      }
    },

    cancelar() {
      this.$router.push('/admin/empresas');
    },

    setEndereco(endereco) {
      this.$store.commit('empresa/setEndereco', endereco);
      this.filtrarCidadesEstado(this.empresa.estado);
    },

    consultarCnae(cnae) {
      if (cnae.length === 9) {
        const cnaeConsulta = cnae.replace('-', '').replace('/', '');
        this.$store.dispatch('empresa/consultarCnae', cnaeConsulta);
      }
    },

    definirInfoComplementar() {
      const configTributaria = this.$store.state.empresa.empresa.configuracaoTributaria;
      if (configTributaria) {
        if (configTributaria.regime_tributario == 1 || configTributaria.regime_tributario == 2) {
          if (configTributaria.csosn == 101) {
            const texto =
              'DOCUMENTO EMITIDO POR ME OU EPP OPTANTE PELO SIMPLES NACIONAL, ' +
              'NÃO GERA DIREITO A CRÉDITO FISCAL DE IPI ' +
              'PERMITE O APROVEITAMENTO DO CRÉDITO DE ICMS CORRESPONDENTE À ALÍQUOTA DE ' +
              configTributaria.aliq_cred_sn +
              '% ' +
              'NOS TERMOS DO ART. 23 DA LEI COMPLEMENTAR Nº 123, DE 2006';
            this.$store.commit('empresa/setInfoComplementar', texto);
          } else if (configTributaria.csosn == 102) {
            const texto = 'DOCUMENTO EMITIDO POR ME OU EPP OPTANTE PELO SIMPLES NACIONAL' + 'NÃO GERA DIREITO A CRÉDITO FISCAL DE IPI';
            this.$store.commit('empresa/setInfoComplementar', texto);
          } else {
            this.$store.commit('empresa/setInfoComplementar', '');
          }
        } else {
          this.$store.commit('empresa/setInfoComplementar', '');
        }
      } else {
        this.$store.commit('empresa/setInfoComplementar', '');
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit('cidades/setCidades', []);
    next();
  },

  ...mapActions('cidades', {
    filtrarCidadesEstado: 'filtrarCidadesEstado',
  }),

  async created() {
    try {
      this.loading = true;
      const estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;

      if (estaEditando && !this.empresa._id) {
        this.$router.push('/admin/empresas');
      } else if (estaEditando) {
        if (this.empresa.estado) await this.filtrarCidadesEstado(this.empresa.estado);
      }

      if (!estaEditando) {
        this.$store.commit('empresa/estadoInicial');
        this.$store.dispatch('contador/listar');
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },
};
</script>
