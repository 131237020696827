<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else ref="formUsuario" lazy-validation>
      <v-container fluid>
        <v-widget title="Usuário" noDivider>
          <div slot="widget-content">
            <v-tabs v-model="abaAtiva">
              <v-tab :key="1">Informações</v-tab>
              <v-tab :key="2">Empresas</v-tab>
            </v-tabs>

            <v-tabs-items v-model="abaAtiva">
              <v-divider />

              <v-tab-item :key="1" eager>
                <v-container fluid class="pa-0 pt-4">
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-text-field outlined persistentPlaceholder label="Nome" autocomplete="off" role="presentation" v-model="usuario.nome" :rules="[rules.obrigatorio]" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field outlined persistentPlaceholder class="pr-4" label="Login" autocomplete="off" role="presentation" v-model="usuario.email" :rules="[rules.obrigatorio]" :suffix="'@' + cnpjComum" @blur="normalizarNome"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" v-if="!modoEdicao || alterarSenha">
                      <v-text-field outlined persistentPlaceholder type="password" label="Senha" autocomplete="off" role="presentation" v-model="usuario.senha" :rules="[rules.obrigatorio]"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" v-if="!modoEdicao || alterarSenha">
                      <v-text-field outlined persistentPlaceholder type="password" label="Confirmar Senha" autocomplete="off" role="presentation" v-model="usuario.senhaConfirmacao"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="modoEdicao && !alterarSenha">
                      <v-btn outlined class="mt-2" @click="editarSenha" style="width: 190px; height: 40px; float: right">Alterar Senha</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item :key="2" eager>
                <v-container fluid class="pa-0">
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-data-table v-model="empresasSelecionadas" show-select :headers="headers" :items="session.empresas" item-key="_id" :loading="carregando" hide-default-footer>
                        <template v-slot:item="props">
                          <tr>
                            <td><v-simple-checkbox :ripple="false" color="primary" :value="props.isSelected" @input="props.select($event)"></v-simple-checkbox></td>
                            <td class="text-no-wrap">{{ props.item.cnpj | formatarCnpj }}</td>
                            <td>{{ props.item.fantasia }}</td>
                            <td>
                              <v-select label="Nível de Acesso" solo flat filled single-line full-width dense :items="constantes.NIVEIS_ACESSO" v-model="props.item.nivelAcesso" :disabled="!props.isSelected" primary hide-details></v-select>
                            </td>
                            <td class="pr-0">
                              <v-btn v-if="props.item.nivelAcesso === 'ADMINISTRADOR'" depressed color="primary" disabled style="width: 190px; height: 40px">ACESSO COMPLETO</v-btn>
                              <v-btn v-else outlined class="text-black" @click="abrirPermissoes(props.item)" style="width: 190px; height: 40px">Permissões</v-btn>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </div>

          <template v-slot:widget-footer-action>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed class="mr-2" @click="cancelar">Cancelar</v-btn>
              <v-btn depressed color="primary" :loading="carregando" @click="salvar">Salvar</v-btn>
            </v-card-actions>
          </template>
        </v-widget>
      </v-container>
    </v-form>

    <v-dialog v-model="permissoes" max-width="800px" @keydown.esc="abrirPermissoes" persistent scrollable>
      <v-card>
        <v-toolbar dense flat dark color="primary">
          <v-toolbar-title>Permissões</v-toolbar-title>
        </v-toolbar>

        <v-container fluid class="pa-0" style="height: 60vh">
          <v-row class="pa-4">
            <v-col cols="12">
              <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @click:clear="limparBusca" prepend-inner-icon="search" label="Buscar permissão"></v-text-field>
            </v-col>
          </v-row>

          <v-row class="" dense>
            <v-col cols="12" sm="6" class="pa-4" style="height: 58vh; overflow: auto">
              <v-list dense expand>
                <v-list-item-group color="primary">
                  <template v-for="(item, i) in menu">
                    <v-subheader v-if="item.cabecalho" :key="i" class="pl-4 pt-2 mt-1">{{ item.cabecalho }}</v-subheader>
                    <v-divider v-else-if="item.divider" :key="i" class="ml-4"></v-divider>
                    <v-list-group v-else-if="item.items" :key="item.nome" no-action :prepend-icon="item.icone" :group="item.grupo" color="primary">
                      <template v-slot:activator>
                        <v-list-item-content :data-cy="item.titulo">
                          <v-list-item-title>{{ item.titulo }}</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-divider />

                      <v-list-item v-for="(subItem, i) in item.items" :key="i" :data-cy="subItem.titulo" :title="subItem.titulo" @click="selecao(subItem)">
                        <v-list-item-content>
                          <v-list-item-title>
                            <span style="font-weight: normal">{{ subItem.titulo }}</span>
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action v-if="subItem.icone">
                          <v-icon>{{ subItem.icone }}</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-group>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-col>

            <v-divider vertical></v-divider>

            <v-col cols="12" sm="6" class="text-center">
              <v-scroll-y-transition mode="out-in">
                <div v-if="!permissaoSelecionada" class="title grey--text text--lighten-1 font-weight-light">
                  <v-label>Selecione uma permissão</v-label>
                </div>

                <v-row v-else class="text-left pa-4" v-show="!aguarde" dense>
                  <v-col cols="12" v-for="(item, i) in permissaoSelecionada.permissoes" :key="i">
                    <v-checkbox class="mt-0" :label="item.titulo" :input-value="checkPermissao(item.nome)" @change="setPermissao($event, item.nome)" hide-details></v-checkbox>
                  </v-col>
                </v-row>
              </v-scroll-y-transition>
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>

        <v-row class="pa-4" dense>
          <v-col cols="12">
            <v-btn depressed style="float: right" color="primary" @click="abrirPermissoes()">Fechar</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import constantes from '@/util/constantes';
import * as regras from '@/util/validacaoFormulario';
import menu from './menuPermissoes';

export default {
  name: 'FormularioUsuario',
  data: () => ({
    carregando: false,
    constantes: constantes,
    rules: { ...regras },
    abaAtiva: 0,
    headers: [
      { text: 'CNPJ', value: 'cnpj', sortable: false, width: '120px' },
      { text: 'Fantasia', value: 'fantasia', sortable: false },
      { text: 'Nível de Acesso', value: 'nive_acesso', sortable: false, width: '220px' },
      { text: '', value: 'permissoes', sortable: false, width: '206px' },
    ],
    menu: menu,
    empresasSelecionadas: [],
    permissaoSelecionada: null,
    aguarde: false,
    permissoes: false,
    empresaId: null,
    busca: '',
    alterarSenha: false,
    cnpjComum: '',
    usuario: {
      nome: '',
      email: '',
      senha: '',
      senhaConfirmacao: '',
    },
    modoEdicao: false,
  }),

  computed: {
    ...mapState('autenticacao', {
      session: 'session',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    abrirPermissoes(empresa) {
      this.permissoes = !this.permissoes;
      if (empresa) this.empresaId = empresa._id;
    },

    limparBusca() {
      this.busca = '';
    },

    normalizarNome() {
      this.usuario.email = this.usuario.email.replace(/^[^A-Za-z0-9]+/, '');
      this.usuario.email = this.usuario.email.replace(/([^A-Za-z0-9]+)/g, '').toUpperCase();
    },

    editarSenha() {
      this.alterarSenha = true;
    },

    selecao(permissao) {
      if (this.isSm) {
        this.scrollToRodape();
      } else {
        this.scrollToTopo();
      }
      this.permissaoSelecionada = permissao;
    },

    checkPermissao(permissao) {
      const empresa = this.session.empresas.find((row) => row._id === this.empresaId);
      return empresa.permissoes.includes(permissao) ? 1 : 0;
    },

    setPermissao(valor, permissao) {
      if (valor) {
        this.$store.commit('usuario/adicionarPermissaoUsuario', { permissao, empresaId: this.empresaId });
      } else {
        this.$store.commit('usuario/removerPermissaoUsuario', { permissao, empresaId: this.empresaId });
      }
    },

    async salvar() {
      if (this.$refs.formUsuario.validate()) {
        try {
          if (!this.usuario._id && this.usuario.senha !== this.usuario.senhaConfirmacao) {
            this.$store.commit('setMensagemErro', 'Senha e confirmação de senha estão diferentes');
            return;
          }

          if (this.usuario._id && this.usuario.senha && this.usuario.senha !== this.usuario.senhaConfirmacao) {
            this.$store.commit('setMensagemErro', 'Senha e confirmação de senha estão diferentes');
            return;
          }

          if (this.empresasSelecionadas.length == 0) {
            this.$store.commit('setMensagemErro', 'Nenhuma empresa selecionada');
            return;
          }

          const nivelAcessoDefinido = this.empresasSelecionadas.filter((empresa) => empresa.nivelAcesso === '').length === 0;
          if (!nivelAcessoDefinido) {
            this.$store.commit('setMensagemErro', 'O nível de acesso não definido em uma ou mais empresas');
            return;
          }

          this.usuario.email = this.usuario.email + '@' + this.cnpjComum;

          await this.$store.dispatch('usuario/salvar', this.empresasSelecionadas);
          this.$router.push('/configuracoes/usuarios');
        } catch (error) {
          this.$store.commit('setMensagemErro', error);
        }
      }
    },

    cancelar() {
      this.$store.commit('usuario/estadoInicial');
      this.$router.push({ name: 'usuarios' });
    },

    scrollToTopo() {
      window.scrollTo(260, 0);
    },

    scrollToRodape() {
      window.scrollTo(0, document.body.scrollHeight);
    },
  },

  async created() {
    try {
      this.cnpjComum = this.$store.state.autenticacao.session.empresa.cnpj.substring(0, 8);
      this.usuario.email = this.usuario.email.split('@')[0];
      this.loading = true;

      // const usuario = this.$store.state.usuarios.usuarioAtual;
      // const estaEditando = this.$router.currentRoute.path.indexOf('editar') >= 0;

      // if (estaEditando && !usuario._id) {
      //   this.$router.push('/configuracoes/usuarios');
      // }

      // if (!estaEditando) {
      //   this.$store.commit('usuario/estadoInicial');
      // }

      // if (estaEditando) {
      //   this.empresasSelecionadas = this.session.empresas.filter((empresa) => empresa.nivelAcesso !== '');
      // }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },
};
</script>
