<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formFilial" lazy-validation>
      <v-container fluid>
        <v-widget title="Filial" :temCampoObrigatorio="true">
          <div slot="widget-content">
            <v-tabs v-model="abaAtiva">
              <v-tab :key="1">Dados da Filial</v-tab>
              <v-tab :key="2">NF-e</v-tab>
              <v-tab :key="3">Configurações Fiscais</v-tab>
            </v-tabs>

            <v-tabs-items v-model="abaAtiva">
              <v-divider></v-divider>

              <v-tab-item :key="1" eager>
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <v-text-field label="CNPJ" v-model="filial.cnpj" @keypress.enter="consultarCnpj(filial.cnpj)" :loading="carregando" v-mask="'##.###.###/####-##'" :rules="validarCnpj" :readonly="estaEditando || carregando"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Inscrição Estadual" v-model="filial.ie"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Inscrição Municipal" v-model="filial.im"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field label="Razão Social" v-model="filial.razao" :rules="validadarrazaoSocialNome"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field label="Nome Fantasia" v-model="filial.fantasia"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <campo-cep v-mask="'#####-###'" label="CEP" buscarcep @resultado="setEndereco" v-model="filial.cep" append-icon="map" data-cy="cep"></campo-cep>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-text-field label="Logradouro" v-model="filial.logradouro" :rules="validarLogradouro"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Número" v-model="filial.numero" :rules="validarNumero"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Complemento" v-model="filial.complemento"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Bairro" v-model="filial.bairro" :rules="validarBairro"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-autocomplete label="Estado" v-model="filial.estado" :items="constantes.ESTADOS_AUTOCOMPLETE" @change="filtrarCidadesEstado(filial.estado)"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-autocomplete
                        label="Cidade *"
                        v-model="filial.codigo_cidade"
                        @change="setCidade(filial.codigo_cidade)"
                        :items="cidades"
                        :rules="validarCidade"
                        :disabled="cidades.length == 0 || $store.state.filial.carregando_cidade"
                        :loading="$store.state.filial.carregando_cidade"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="País" v-model="filial.pais" :rules="validarPais" readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Telefone" v-model="filial.telefone" :rules="validarTelefone" v-mask="'(##)####-####'"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-text-field label="E-Mail" v-model="filial.email" :rules="validarEmail"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item :key="2" eager>
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="12" sm="2">
                      <v-select label="Versão NF-e" v-model="filial.versao" :items="constantes.VERSAO_NFE"></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-select label="Ambiente *" v-model="filial.ambiente" :items="constantes.AMBIENTES" :rules="validarAmbiente"></v-select>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-select label="Formato de Impressão" v-model="filial.formato_impressao" :items="constantes.FORMATO_IMPRESSAO"></v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-text-field label="Série Padrão *" v-model="filial.configuracao_tributaria.serie_padrao" v-mask="'###'" :rules="validarSerie" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-select label="Modo de Utilização" :items="constantes.TIPOS_MODO" v-model="filial.plano" @change="alterarModoUtilizacao()"></v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-select label="Frete *" v-model="filial.frete_padrao" :items="constantes.MODELO_FRETE" :rules="validarFretePadrao"></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-select label="Forma de Pgto. Padrão *" v-model="filial.forma_pagamento_padrao" :items="constantes.FORMA_PAGAMENTO" :rules="validarFormaPgtoPadrao"></v-select>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-select label="Cácular Preço de Custo" v-model="filial.calculo_custo" :items="constantes.CALCULOS_PRECO_CUSTO"></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item :key="3" eager>
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <v-text-field label="CNAE *" v-model="filial.cnae" v-mask="'####-#/##'" @input="consultarCnae(filial.cnae)" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-text-field label="Descrição da Atividade Econômica" v-model="filial.descricao_cnae" readonly required :rules="validarDescCnae"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select label="Ramo de Atividade *" v-model="filial.configuracao_tributaria.ramo_atividade" :items="constantes.RAMO_ATIVIDADES" @change="definirCfopRamoAtividade" required :rules="validarRamoAtiv"></v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select label="Configuração CFOP *" @change="atualizarConfigCfop()" v-model="filial.configuracao_tributaria.config_cfop_padrao" :items="constantes.CONFIG_CFOP_PADRAO" :readonly="filial.plano === constantes.MODO_DEPOSITO"></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" v-if="filial.configuracao_tributaria.config_cfop_padrao !== '1'">
                      <v-text-field label="CFOP Padrão *" v-mask="'####'" v-model="filial.configuracao_tributaria.cfop_padrao" :rules="validarCfopPadrao"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select label="Regime Tributário *" v-model="filial.configuracao_tributaria.regime_tributario" :items="constantes.REGIMES_TRIBUTARIOS" required :rules="validarRegime"></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" v-if="ehSimplesNacional">
                      <v-select label="CSOSN *" :items="constantes.CSOSN" v-model="filial.configuracao_tributaria.csosn" @change="definirInfoComplementar()" required :rules="validarCsosn"></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" v-if="ehSimplesNacionalComPermissaoCredito">
                      <v-text-field label="Alíq. Créd. SN" v-model="filial.configuracao_tributaria.aliq_cred_sn" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-textarea label="Informações Complementares" v-model="filial.configuracao_tributaria.info_complementar" required></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </div>
          <template v-slot:widget-footer-action>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed class="mr-2" @click="cancelar">Cancelar</v-btn>
              <v-btn depressed color="primary" :loading="carregando" @click="salvar()" :disabled="!valid">{{ textoBtnConfirmar }}</v-btn>
            </v-card-actions>
          </template>
        </v-widget>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import constantes from '@/util/constantes';
import validador from '@/util/validador';
import { mask } from 'vue-the-mask';

export default {
  directives: {
    mask,
  },

  data: () => ({
    valid: false,
    validarSerie: [(v) => !!v || 'Série obrigatória', (v) => (v && v.length === 3) || 'Formato Inválido, ex: 001'],
    validarCnpj: [(v) => (v && v.length === 18 ? validador.cnpj(v) : false) || 'CNPJ Inválido'],
    validarInscricao: [(v) => !!v || 'Inscrição Obrigatória'],
    validadarrazaoSocialNome: [(v) => !!v || 'Razão/Nome Obrigatório'],
    validarCep: [(v) => !!v || 'CEP Obrigatório'],
    validarLogradouro: [(v) => !!v || 'Logradouro Obrigatório'],
    validarNumero: [(v) => !!v || 'Número Obrigatório'],
    validarBairro: [(v) => !!v || 'Bairro Obrigatório'],
    validarCidade: [(v) => !!v || 'Cidade Obrigatória'],
    validarPais: [(v) => !!v || 'País Obrigatório'],
    validarTelefone: [(v) => !!v || 'Telefone Obrigatório'],
    validarAmbiente: [(v) => !!v || 'Ambiente Obrigatório'],
    validarFretePadrao: [(v) => !!v || 'Frete Padrão Obrigatório'],
    validarFormaPgtoPadrao: [(v) => !!v || 'Frete Padrão Obrigatório'],
    validarTipoCertificado: [(v) => !!v || 'Tipo de Cert. Obrigatório'],
    validarDescCnae: [(v) => !!v || 'Informe um CNAE válido'],
    validarRamoAtiv: [(v) => !!v || 'Ramo Obrigatório'],
    validarCfopPadrao: [(v) => !!v || 'CFOP Padrão Obrigatório'],
    validarRegime: [(v) => !!v || 'Regime Trib. Obrigatório'],
    validarCsosn: [(v) => !!v || 'CSOSN Obrigatório'],
    validarEmail: [(v) => (v && validador.email(v)) || 'E-mail Inválido'],
    validarNomeUsuario: [(v) => !!v || 'Nome do usuário obrigatório'],
    constantes: constantes,
    cidadeSelecionada: {},
    abaAtiva: 0,
    loading: false,
  }),

  computed: {
    ...mapState('filial', {
      filial: 'filialAtual',
      carregando: 'carregando',
    }),
    ...mapGetters('filial', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar',
      cidades: 'listaCidades',
    }),

    ehSimplesNacional() {
      let configTributaria = this.$store.state.filial.filialAtual.configuracao_tributaria;
      if (configTributaria) {
        if (configTributaria.regime_tributario === 1 || configTributaria.regime_tributario === 2) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    ehSimplesNacionalComPermissaoCredito() {
      let configTributaria = this.$store.state.filial.filialAtual.configuracao_tributaria;
      if (configTributaria) {
        if ((configTributaria.regime_tributario === 1 || configTributaria.regime_tributario === 2) && configTributaria.csosn == 101) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions('filial', {
      filtrarCidadesEstado: 'filtrarCidadesEstado',
    }),

    ...mapMutations('filial', {
      setCidade: 'setCidade',
      atualizarConfigCfop: 'atualizarConfigCfop',
    }),

    consultarCnpj(cnpj) {
      if (cnpj.length === 18 && !this.carregando) {
        let cnpjConsulta = cnpj.replace('.', '').replace('.', '').replace('/', '').replace('/', '').replace('-', '');
        this.$store.dispatch('sefaz/consultarCadastro', cnpjConsulta);
      }
    },

    alterarModoUtilizacao() {
      let plano = this.filial.plano;
      if (plano == constantes.MODO_NFE) {
        this.filial.configuracao_tributaria.config_cfop_padrao = '1';
      } else if (plano == constantes.MODO_DEPOSITO) {
        this.filial.configuracao_tributaria.config_cfop_padrao = '3';
      }
    },

    definirCfopRamoAtividade() {
      let configTrib = this.filial.configuracao_tributaria;
      if (configTrib.ramo_atividade == '1') {
        //comércio
        configTrib.cfop_padrao = '5102';
      } else if (configTrib.ramo_atividade == '3') {
        //indústria
        configTrib.cfop_padrao = '5101';
      }
    },

    async salvar() {
      if (this.$refs.formFilial.validate()) {
        try {
          let inscricao = `${this.filial.ie}-${this.filial.estado}`;
          let isInscricaoValida = validador.inscricaoEstadual(inscricao);
          if (isInscricaoValida) {
            await this.$store.dispatch('filial/salvar');
            this.$router.push('/filiais');
          } else {
            this.$store.commit('setMensagemErro', `Inscrição ${inscricao} inválida`);
          }
        } catch (error) {
          this.$store.commit('setMensagemErro', error);
        }
      }
    },

    cancelar() {
      this.$router.push('/filiais');
    },

    setEndereco(endereco) {
      this.$store.commit('filial/setEndereco', endereco);
      this.filtrarCidadesEstado(this.filial.estado);
    },

    consultarCnae(cnae) {
      if (cnae.length === 9) {
        let cnaeConsulta = cnae.replace('-', '').replace('/', '');
        this.$store.dispatch('filial/consultarCnae', cnaeConsulta);
      }
    },

    preencherEmailPadrao() {
      this.abaUsuario = true;
      this.$store.commit('filial/preencherEmailPadrao');
    },

    definirInfoComplementar() {
      let configTributaria = this.$store.state.filial.filialAtual.configuracao_tributaria;
      if (configTributaria) {
        if (configTributaria.regime_tributario == 1 || configTributaria.regime_tributario == 2) {
          if (configTributaria.csosn == 101) {
            let texto =
              'DOCUMENTO EMITIDO POR ME OU EPP OPTANTE PELO SIMPLES NACIONAL, ' +
              'NÃO GERA DIREITO A CRÉDITO FISCAL DE IPI ' +
              'PERMITE O APROVEITAMENTO DO CRÉDITO DE ICMS CORRESPONDENTE À ALÍQUOTA DE ' +
              configTributaria.aliq_cred_sn +
              '% ' +
              'NOS TERMOS DO ART. 23 DA LEI COMPLEMENTAR Nº 123, DE 2006';
            this.$store.commit('filial/setInfoComplementar', texto);
          } else if (configTributaria.csosn == 102) {
            let texto = 'DOCUMENTO EMITIDO POR ME OU EPP OPTANTE PELO SIMPLES NACIONAL' + 'NÃO GERA DIREITO A CRÉDITO FISCAL DE IPI';
            this.$store.commit('filial/setInfoComplementar', texto);
          } else {
            this.$store.commit('filial/setInfoComplementar', '');
          }
        } else {
          this.$store.commit('filial/setInfoComplementar', '');
        }
      } else {
        this.$store.commit('filial/setInfoComplementar', '');
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit('filial/setCidades', []);
    next();
  },

  async created() {
    try {
      this.loading = true;
      const estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;

      if (estaEditando && (!this.filial._id || this.filial._id === '')) {
        this.$store.commit('setMensagemErro', 'Selecione uma filial para editar');
        this.$router.push('/filiais');
      } else if (estaEditando) {
        if (this.filial.estado) await this.filtrarCidadesEstado(this.filial.estado);
      }

      if (!estaEditando) {
        this.$store.commit('filial/estadoInicial');
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },
};
</script>
