const MenuPermissoes = [
  {
    titulo: 'Cadastros',
    grupo: 'Cadastros',
    nome: 'Cadastros',
    icone: 'store',
    items: [
      { nome: 'Menu', titulo: 'Menu', icone: 'mdi-format-list-bulleted', grupo: 'Cadastros', permissoes: [{ nome: 'exibir_cadastros', titulo: 'Exibir no Menu Lateral' }] },
      {
        nome: 'Clientes/Fornecedores',
        titulo: 'Clientes/Fornecedores',
        icone: 'people_outline',
        grupo: 'Cadastros',
        permissoes: [
          { nome: 'acessar_participantes', titulo: 'Acessar' },
          { nome: 'adicionar_participantes', titulo: 'Adicionar' },
          { nome: 'alterar_participantes', titulo: 'Alterar' },
          { nome: 'excluir_participantes', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'Produto',
        titulo: 'Produtos',
        icone: 'kitchen',
        grupo: 'Cadastros',
        permissoes: [
          { nome: 'acessar_produtos', titulo: 'Acessar' },
          { nome: 'adicionar_produtos', titulo: 'Adicionar' },
          { nome: 'adicionar_produtosnfe', titulo: 'Adicionar Produto NF-e' },
          { nome: 'alterar_produtos', titulo: 'Alterar' },
          { nome: 'alterar_produtosnfe', titulo: 'Alterar Produto NF-e' },
          { nome: 'excluir_produtos', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'Transportador',
        titulo: 'Transportadores',
        icone: 'event_seat',
        grupo: 'Cadastros',
        permissoes: [
          { nome: 'acessar_transportadores', titulo: 'Acessar' },
          { nome: 'adicionar_transportadores', titulo: 'Adicionar' },
          { nome: 'alterar_transportadores', titulo: 'Alterar' },
          { nome: 'excluir_transportadores', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'Grupo',
        titulo: 'Grupos',
        icone: 'group_work',
        grupo: 'Cadastros',
        permissoes: [
          { nome: 'acessar_grupos', titulo: 'Acessar' },
          { nome: 'adicionar_grupos', titulo: 'Adicionar' },
          { nome: 'alterar_grupos', titulo: 'Alterar' },
          { nome: 'excluir_grupos', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'Fabricante',
        titulo: 'Fabricantes',
        icone: 'gavel',
        grupo: 'Cadastros',
        permissoes: [
          { nome: 'acessar_fabricantes', titulo: 'Acessar' },
          { nome: 'adicionar_fabricantes', titulo: 'Adicionar' },
          { nome: 'alterar_fabricantes', titulo: 'Alterar' },
          { nome: 'excluir_fabricantes', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'Vendedor',
        titulo: 'Vendedores',
        icone: 'sentiment_very_satisfied',
        grupo: 'Cadastros',
        permissoes: [
          { nome: 'acessar_vendedores', titulo: 'Acessar' },
          { nome: 'adicionar_vendedores', titulo: 'Adicionar' },
          { nome: 'alterar_vendedores', titulo: 'Alterar' },
          { nome: 'excluir_vendedores', titulo: 'Excluir' },
        ],
      },
    ],
  },

  {
    titulo: 'Movimentações',
    grupo: 'Movimentações',
    nome: 'Movimentações',
    icone: 'import_export',
    items: [
      { nome: 'Menu', titulo: 'Menu', icone: 'mdi-format-list-bulleted', grupo: 'Movimentações', permissoes: [{ nome: 'exibir_movimentacoes', titulo: 'Exibir no Menu Lateral' }] },
      {
        nome: 'Vendas',
        titulo: 'Vendas',
        icone: 'shopping_cart',
        grupo: 'Movimentações',
        permissoes: [
          { nome: 'acessar_vendas', titulo: 'Acessar' },
          { nome: 'adicionar_vendas', titulo: 'Adicionar' },
          { nome: 'detalhar_vendas', titulo: 'Detalhar' },
          { nome: 'alterar_vendas', titulo: 'Alterar' },
          { nome: 'excluir_vendas', titulo: 'Excluir' },
          { nome: 'imprimir_vendas', titulo: 'Imprimir' },
          { nome: 'acertar_vendas', titulo: 'Acertar' },
        ],
      },
      {
        nome: 'Contas a Receber',
        titulo: 'Contas a Receber',
        icone: 'vertical_align_bottom',
        grupo: 'Movimentações',
        permissoes: [
          { nome: 'acessar_contas_receber', titulo: 'Acessar' },
          { nome: 'adicionar_contas_receber', titulo: 'Adicionar' },
          { nome: 'alterar_contas_receber', titulo: 'Alterar' },
          { nome: 'excluir_contas_receber', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'Contas a Pagar',
        titulo: 'Contas a Pagar',
        icone: 'vertical_align_top',
        grupo: 'Movimentações',
        permissoes: [
          { nome: 'acessar_contas_pagar', titulo: 'Acessar' },
          { nome: 'adicionar_contas_pagar', titulo: 'Adicionar' },
          { nome: 'alterar_contas_pagar', titulo: 'Alterar' },
          { nome: 'excluir_contas_pagar', titulo: 'Excluir' },
          { nome: 'baixar_contas_pagar', titulo: 'Baixar' },
        ],
      },
      {
        nome: 'Cheques',
        titulo: 'Cheques',
        icone: 'local_atm',
        grupo: 'Movimentações',
        permissoes: [
          { nome: 'acessar_cheques', titulo: 'Acessar' },
          { nome: 'adicionar_cheques', titulo: 'Adicionar' },
          { nome: 'alterar_cheques', titulo: 'Alterar' },
          { nome: 'excluir_cheques', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'Movimentos de Estoque',
        titulo: 'Movimento de Estoque',
        icone: 'rv_hookup',
        grupo: 'Movimentações',
        permissoes: [
          { nome: 'acessar_movimentosestoque', titulo: 'Acessar' },
          { nome: 'acessar_estoquesimples', titulo: 'Acessar Estoque Simples' },
          { nome: 'acessar_estoquedeposito', titulo: 'Acessar Estoque Depósito' },
          { nome: 'adicionar_movimentosestoque', titulo: 'Adicionar' },
          { nome: 'alterar_movimentosestoque', titulo: 'Alterar' },
          { nome: 'excluir_movimentosestoque', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'Caixa',
        titulo: 'Caixa',
        icone: 'swap_horiz',
        grupo: 'Movimentações',
        permissoes: [
          { nome: 'acessar_caixa', titulo: 'Acessar' },
          { nome: 'adicionar_caixa', titulo: 'Adicionar' },
          { nome: 'alterar_caixa', titulo: 'Alterar' },
          { nome: 'excluir_caixa', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'Ordens de Serviço',
        titulo: 'Ordens de Serviço',
        icone: 'mdi-checkbox-marked-outline',
        grupo: 'Movimentações',
        permissoes: [
          { nome: 'acessar_ordensservico', titulo: 'Acessar' },
          { nome: 'adicionar_ordensservico', titulo: 'Adicionar' },
          { nome: 'alterar_ordensservico', titulo: 'Alterar' },
          { nome: 'excluir_ordensservico', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'NFEs de Entrada',
        titulo: 'NFEs de Entrada',
        icone: 'business_center',
        grupo: 'Movimentações',
        permissoes: [
          { nome: 'acessar_compras', titulo: 'Acessar' },
          { nome: 'adicionar_compras', titulo: 'Adicionar' },
          { nome: 'alterar_compras', titulo: 'Alterar' },
          { nome: 'excluir_compras', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'Pedidos de Clientes',
        titulo: 'Pedidos de Clientes',
        icone: 'mdi-application',
        grupo: 'Movimentações',
        permissoes: [
          { nome: 'acessar_pedidosclientes', titulo: 'Acessar' },
          { nome: 'adicionar_pedidosclientes', titulo: 'Adicionar' },
          { nome: 'alterar_pedidosclientes', titulo: 'Alterar' },
          { nome: 'excluir_pedidosclientes', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'Pedidos de Compras',
        titulo: 'Pedidos de Compras',
        icone: 'mdi-card-plus',
        grupo: 'Movimentações',
        permissoes: [
          { nome: 'acessar_pedidoscompras', titulo: 'Acessar' },
          { nome: 'adicionar_pedidoscompras', titulo: 'Adicionar' },
          { nome: 'alterar_pedidoscompras', titulo: 'Alterar' },
          { nome: 'excluir_pedidoscompras', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'Movimento de Produção',
        titulo: 'Movimento de Produção',
        icone: 'mdi-factory',
        grupo: 'Movimentações',
        permissoes: [
          { nome: 'acessar_movimentosproducao', titulo: 'Acessar' },
          { nome: 'adicionar_movimentosproducao', titulo: 'Adicionar' },
          { nome: 'alterar_movimentosproducao', titulo: 'Alterar' },
          { nome: 'excluir_movimentosproducao', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'Remessas de Cheques',
        titulo: 'Remessas de Cheques',
        icone: 'mdi-send-outline',
        grupo: 'Movimentações',
        permissoes: [
          { nome: 'acessar_remessascheque', titulo: 'Acessar' },
          { nome: 'adicionar_remessascheque', titulo: 'Adicionar' },
          { nome: 'alterar_remessascheque', titulo: 'Alterar' },
          { nome: 'excluir_remessascheque', titulo: 'Excluir' },
        ],
      },
    ],
  },

  {
    titulo: 'Tabelas',
    grupo: 'Tabelas',
    nome: 'Tabela',
    icone: 'table_chart',
    items: [
      { nome: 'Menu', titulo: 'Menu', icone: 'mdi-format-list-bulleted', grupo: 'Tabelas', permissoes: [{ nome: 'exibir_tabelas', titulo: 'Exibir no Menu Lateral' }] },
      {
        nome: 'Banco',
        titulo: 'Bancos',
        icone: 'account_balance',
        grupo: 'Tabelas',
        permissoes: [
          { nome: 'acessar_bancos', titulo: 'Acessar' },
          { nome: 'adicionar_bancos', titulo: 'Adicionar' },
          { nome: 'alterar_bancos', titulo: 'Alterar' },
          { nome: 'excluir_bancos', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'Conta Corrente',
        titulo: 'Contas Correntes',
        icone: 'monetization_on',
        grupo: 'Tabelas',
        permissoes: [
          { nome: 'acessar_contascorrentes', titulo: 'Acessar' },
          { nome: 'adicionar_contascorrentes', titulo: 'Adicionar' },
          { nome: 'alterar_contascorrentes', titulo: 'Alterar' },
          { nome: 'excluir_contascorrentes', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'PIS',
        titulo: 'PIS',
        icone: 'money_off',
        grupo: 'Tabelas',
        permissoes: [
          { nome: 'acessar_pis', titulo: 'Acessar' },
          { nome: 'adicionar_pis', titulo: 'Adicionar' },
          { nome: 'alterar_pis', titulo: 'Alterar' },
          { nome: 'excluir_pis', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'COFINS',
        titulo: 'COFINS',
        icone: 'money_off',
        grupo: 'Tabelas',
        permissoes: [
          { nome: 'acessar_cofins', titulo: 'Acessar' },
          { nome: 'adicionar_cofins', titulo: 'Adicionar' },
          { nome: 'alterar_cofins', titulo: 'Alterar' },
          { nome: 'excluir_cofins', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'Estados e Cidades',
        titulo: 'Estados e Cidades',
        icone: 'location_city',
        grupo: 'Tabelas',
        permissoes: [
          { nome: 'acessar_cidadesestados', titulo: 'Acessar' },
          { nome: 'adicionar_cidadesestados', titulo: 'Adicionar' },
          { nome: 'alterar_cidadesestados', titulo: 'Alterar' },
          { nome: 'excluir_cidadesestados', titulo: 'Excluir' },
        ],
      },
      // {
      //   nome: 'Cor',
      //   titulo: 'Cores',
      //   icone: 'format_color_fill',
      //   grupo: 'Tabelas',
      //   permissoes: [
      //     { nome: 'acessar_cores', titulo: 'Acessar' },
      //     { nome: 'adicionar_cores', titulo: 'Adicionar' },
      //     { nome: 'alterar_cores', titulo: 'Alterar' },
      //     { nome: 'excluir_cores', titulo: 'Excluir' },
      //   ],
      // },
      // {
      //   nome: 'Tamanho',
      //   titulo: 'Tamanhos',
      //   icone: 'format_size',
      //   grupo: 'Tabelas',
      //   permissoes: [
      //     { nome: 'acessar_tamanhos', titulo: 'Acessar' },
      //     { nome: 'adicionar_tamanhos', titulo: 'Adicionar' },
      //     { nome: 'alterar_tamanhos', titulo: 'Alterar' },
      //     { nome: 'excluir_tamanhos', titulo: 'Excluir' },
      //   ],
      // },
      {
        nome: 'MVA',
        titulo: 'MVAs',
        icone: 'broken_image',
        grupo: 'Tabelas',
        permissoes: [
          { nome: 'acessar_mvas', titulo: 'Acessar' },
          { nome: 'adicionar_mvas', titulo: 'Adicionar' },
          { nome: 'alterar_mvas', titulo: 'Alterar' },
          { nome: 'excluir_mvas', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'Centro de Custo',
        titulo: 'Centros de Custos',
        icone: 'list_alt',
        grupo: 'Tabelas',
        permissoes: [
          { nome: 'acessar_centroscustos', titulo: 'Acessar' },
          { nome: 'adicionar_centroscustos', titulo: 'Adicionar' },
          { nome: 'alterar_centroscustos', titulo: 'Alterar' },
          { nome: 'excluir_centroscustos', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'CFOP Compra x Entrada',
        titulo: 'Mapeamento CFOPs',
        icone: 'zoom_out_map',
        grupo: 'Tabelas',
        permissoes: [
          { nome: 'acessar_cfopcompraentrada', titulo: 'Acessar' },
          { nome: 'adicionar_cfopcompraentrada', titulo: 'Adicionar' },
          { nome: 'alterar_cfopcompraentrada', titulo: 'Alterar' },
          { nome: 'excluir_cfopcompraentrada', titulo: 'Excluir' },
        ],
      },
      {
        nome: 'Plano de Conta',
        titulo: 'Planos de Conta',
        icone: 'notes',
        grupo: 'Tabelas',
        permissoes: [
          { nome: 'acessar_planosconta', titulo: 'Acessar' },
          { nome: 'adicionar_planosconta', titulo: 'Adicionar' },
          { nome: 'alterar_planosconta', titulo: 'Alterar' },
          { nome: 'excluir_planosconta', titulo: 'Excluir' },
        ],
      },
      // {
      //   nome: 'Local de Estoque',
      //   titulo: 'Locais de Estoque',
      //   icone: 'place',
      //   grupo: 'Tabelas',
      //   permissoes: [
      //     { nome: 'acessar_locaisestoque', titulo: 'Acessar' },
      //     { nome: 'adicionar_locaisestoque', titulo: 'Adicionar' },
      //     { nome: 'alterar_locaisestoque', titulo: 'Alterar' },
      //     { nome: 'excluir_locaisestoque', titulo: 'Excluir' },
      //   ],
      // },
    ],
  },

  {
    titulo: 'Gráficos',
    grupo: 'Gráficos',
    nome: 'Gráficos',
    icone: 'mdi-chart-bar',
    items: [
      {
        titulo: 'Menu',
        grupo: 'Gráficos',
        nome: 'Menu',
        icone: 'mdi-format-list-bulleted',
        permissoes: [{ nome: 'exibir_graficos', titulo: 'Exibir no Menu Lateral' }],
      },
    ],
  },

  {
    titulo: 'Fiscal',
    grupo: 'Fiscal',
    nome: 'Fiscal',
    icone: 'mdi-note',
    items: [
      { nome: 'Menu', titulo: 'Menu', icone: 'mdi-format-list-bulleted', grupo: 'Fiscal', permissoes: [{ nome: 'exibir_fiscal', titulo: 'Exibir no Menu Lateral' }] },
      {
        titulo: 'Notas Fiscais',
        grupo: 'Fiscal',
        nome: 'Notas Fiscais',
        icone: 'mdi-feature-search',
        permissoes: [
          { nome: 'acessar_notasfiscais', titulo: 'Acessar Notas Fiscais' },
          { nome: 'adicionar_notasfiscais', titulo: 'Adicionar Notas Fiscais' },
          { nome: 'alterar_notasfiscais', titulo: 'Alterar Notas Fiscais' },
          { nome: 'transferir_notasfiscais', titulo: 'Tranferência de Notas Fiscais' },
          { nome: 'acessar_notas_destinadas', titulo: 'Acessar Notas Destinadas' },
          { nome: 'acessar_manifestos', titulo: 'Acessar Manifestos' },
          { nome: 'adicionar_manifestos', titulo: 'Adicionar Manifestos' },
          { nome: 'alterar_manifestos', titulo: 'Alterar Manifestos' },
          { nome: 'acessar_manifestosdamfe', titulo: 'Acessar Manifestos DAMFE' },
          { nome: 'acessar_danfe', titulo: 'Acessar Notas DANFE' },
          { nome: 'adicionar_danfe', titulo: 'Adicionar Notas DANFE' },
        ],
      },
    ],
  },
];

export default MenuPermissoes;
